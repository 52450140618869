import React, { useState, useEffect } from 'react';
import { IListaEmails } from './interfaces/IListaEmails';
import axios from 'axios';
import { Table, TableHead, TableRow, TableCell, TableBody, Button, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert, Box, Checkbox } from '@mui/material';
import { deleteTemplateById, getAllTemplates, sendTemplateProd } from '../../services/api';
import { Delete, Edit, Preview, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/common/Pagination';

const ListaEmails: React.FC = () => {
    const [templates, setTemplates] = useState<IListaEmails[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [templateToDelete, setTemplateToDelete] = useState<number | null>(null);
    const [paginaAtual, setPaginaAtual] = useState<number>(1);
    const [totalPaginas, setTotalPaginas] = useState<number>(1);
    const [templatesPorPagina] = useState<number>(10);
    const [msg, setMsg] = useState('');
    const [severity, setSeverity] = useState<'success' | 'error'>('success');
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    

    const navigate = useNavigate();

    const sendSelectedIdsToProd = async () => {
        try {
            const response = await sendTemplateProd(selectedIds);
            setDialogMessage("Templates enviados com sucesso!");
            setSelectedIds([]);
        } catch (error) {
            setDialogMessage("Erro ao enviar os templates.");
        }
        setOpenDialog(true);
    }
    const fetchTemplates = async () => {
        setLoading(true);
        try {
            const response = await getAllTemplates();
            const sortedData = response.data.sort((a: IListaEmails, b: IListaEmails) => b.id - a.id);
            setTemplates(sortedData);
            setTotalPaginas(Math.ceil(sortedData.length / templatesPorPagina));
        } catch (err) {
            setError("Erro ao carregar templates");
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteTemplate = async () => {
        if (templateToDelete === null) return;
        setLoading(true);
        try {
            await deleteTemplateById(templateToDelete);
            setTemplates(prevTemplates => prevTemplates.filter(template => template.id !== templateToDelete));
            setTotalPaginas(Math.ceil((templates.length - 1) / templatesPorPagina));
        } catch (error) {
            setError("Erro ao deletar template");
        } finally {
            setLoading(false);
            handleCloseConfirmDialog();
        }
    };

    const handleEditTemplate = (id: number) => {
        navigate(`/templatesemail/EditorTemplates/${id}`);
}

    const handleOpenConfirmDialog = (id: number) => {
        setTemplateToDelete(id);
        setOpenConfirmDialog(true);
}

    const handleCloseConfirmDialog = () => {
        setTemplateToDelete(null);
        setOpenConfirmDialog(false);
}

    const handleCheckboxChange = (id: any) => {
        setSelectedIds((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((selectedId) => selectedId !== id)
                : [...prevSelected, id]
        );
    }

const PaginaAnterior = () => {
    setPaginaAtual((prev) => Math.max(prev - 1, 1));
};

const ProximaPagina = () => {
    setPaginaAtual((prev) => Math.min(prev + 1, totalPaginas));
};

const IrParaPagina = (pageNumber: number) => {
    setPaginaAtual(pageNumber);
};

const IrParaPrimeiraPagina = () => {
    setPaginaAtual(1);
};

const IrParaUltimaPagina = () => {
    setPaginaAtual(totalPaginas);
};

const indexInicio = (paginaAtual - 1) * templatesPorPagina;
const indexFim = indexInicio + templatesPorPagina;
const templatesPaginados = templates.slice(indexInicio, indexFim);

    const openPreviewInNewTab = (template: { email_Template: any; }) => {
        const newWindow = window.open();
        if (newWindow) {
            newWindow.document.write(`
                <html>
                    <head>
                        <title>Pré-visualização do Template</title>
                    </head>
                    <body>
                    ${template.email_Template}
                    </body>
                </html>
            `);
            newWindow.document.close();
        }
}
    useEffect(() => {
        fetchTemplates();
}, []);

return (
<div>
    {loading && <div>Carregando...</div>}
    {error && <div>{error}</div>}

    <Table>
    <TableHead>
        <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Assunto</TableCell>
            <TableCell>Dias para Envio</TableCell>
            <TableCell sx={{ textAlign: "center", verticalAlign: "middle" }}>
            Ações
        </TableCell>
        <TableCell sx={{ textAlign: "center", verticalAlign: "middle" }}>Enviar para prod</TableCell>
        </TableRow>
    </TableHead>
    <TableBody>
        {templatesPaginados.map((template, index) => (
        <TableRow key={index}>
            <TableCell>{template.id}</TableCell>
            <TableCell>{template.tipo}</TableCell>
            <TableCell>{template.ativo ? "Ativo" : "Inativo"}</TableCell>
            <TableCell>{template.email_Assunto}</TableCell>
            <TableCell>{template.dias_Para_Envio}</TableCell>
            <TableCell>
            <Box
                sx={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Tooltip title="Visualizar template" placement="top">
                    <Button
                    sx={{
                        backgroundColor: "#00A651",
                        color: "#fff",
                        ":hover": { backgroundColor: "#007D3D" },
                    }}
                    onClick={() => openPreviewInNewTab(template)}
                >
                    <Visibility sx={{ fontSize: "medium" }} />
                    </Button>
                </Tooltip>
                <Tooltip title="Editar template" placement="top">
                <Button
                    sx={{
                        marginLeft: "0px",
                        textTransform: "capitalize",
                        backgroundColor: "#3B5998",
                        color: "#fff",
                        ":hover": { backgroundColor: "#30487B" },
                    }}
                    onClick={() => handleEditTemplate(template.id)}
                >
                    <Edit sx={{ fontSize: "medium" }} />
                </Button>
                </Tooltip>
                <Tooltip
                    title={`Deletar template Id: ${template.id}`}
                    placement="top"
                >
                <Button
                    sx={{
                        marginLeft: "0px",
                        backgroundColor: "#FF0000",
                        color: "#fff",
                        ":hover": { backgroundColor: "#AD0000" },
                    }}
                    onClick={() => handleOpenConfirmDialog(template.id)}
                >
                    <Delete sx={{ fontSize: "medium" }} />
                </Button>
                </Tooltip>
            </Box>
            </TableCell>
            <TableCell>
            <Box
                sx={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Checkbox 
                checked={selectedIds.includes(template.id)}
                onChange={() => handleCheckboxChange(template.id)} />
            </Box>
            </TableCell>
        </TableRow>
        ))}
        </TableBody>
    </Table>
    <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={sendSelectedIdsToProd}>
            Enviar Selecionados para o Banco de Prod
        </Button>
    </Box>
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
            <p>{dialogMessage}</p>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
                Ok
            </Button>
        </DialogActions>
    </Dialog>
    <Pagination
        paginaAtual={paginaAtual}
        totalPaginas={totalPaginas}
        PaginaAnterior={PaginaAnterior}
        ProximaPagina={ProximaPagina}
        IrParaPagina={IrParaPagina}
        IrParaPrimeiraPagina={IrParaPrimeiraPagina}
        IrParaUltimaPagina={IrParaUltimaPagina}
    />
    <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirmação</DialogTitle>
        <DialogContent>
        <DialogContentText>
            Você gostaria de deletar o e-mail com id: {templateToDelete}?
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseConfirmDialog} sx={{ backgroundColor: "#3B5998", color: "#fff", ":hover": { backgroundColor: "#30487B" } }}>
            Cancelar
        </Button>
        <Button onClick={handleDeleteTemplate} sx={{ backgroundColor: "#ff0000", color: "#fff", ":hover": { backgroundColor: "#AD0000" } }}>
            Deletar
        </Button>
        </DialogActions>
    </Dialog>
</div>
);
};

export default ListaEmails;
