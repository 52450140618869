import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState, useContext, } from "react";
import Relatorio from "../../../components/common/Relatorio";
import { AuthContext } from "../../../contexts/auth";
import "../../../styles/styles.css";
import { IEmailSindico } from "./Interfaces/IEmailSindico";
export default function RelatorioEsteiraRenovacao() {
const {buscaRelatorioEmailSindico, exportaRelatorioEmailSindico } = useContext(AuthContext);
const [ficha, setFicha] = useState<IEmailSindico[]>([]);
    return (
        <>
            <Typography variant="h6">Relatório de Emails para Síndicos</Typography>
            <Relatorio
                busca={buscaRelatorioEmailSindico}
                exporta={exportaRelatorioEmailSindico}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"EmailSindico"}
            />
            <Divider variant="middle" />
        </>
    );
}