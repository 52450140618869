import { Box, Checkbox, FormControl, Grid, SelectChangeEvent, TextField } from "@mui/material";

interface IFilterFormRelatorioEmailSindico {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: SelectChangeEvent<HTMLSelectElement>) => void;
    formData: any;
}
const FilterFormRelatorioEmailSindico: React.FC<IFilterFormRelatorioEmailSindico> = ({ handleChange, handleChangeDate, formData }) => {

    const defaultNomeDestinatario = formData.NomeDestinatario ? formData.NomeDestinatario : "";
    const defaultEmailDestinatario = formData.EmailDestinatario ? formData.EmailDestinatario : "";
    const defaultDataDe = formData.dataInicial ? formData.dataInicial.toISOString().substring(0, 10) : "";
    const defaultDataAte = formData.dataFinal ? formData.dataFinal.toISOString().substring(0, 10) : "";
    const campoObrigatorio = (formData.dataInicial && formData.dataFinal);

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <FormControl sx={{ width: '100%' }}>
                    <TextField
                        label="Nome"
                        type="text"
                        name="NomeDestinatario"
                        value={defaultNomeDestinatario}
                        onChange={handleChange}
                        fullWidth
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl sx={{ width: '100%' }}>
                    <TextField
                        label="Email"
                        type="text"
                        name="EmailDestinatario"
                        value={defaultEmailDestinatario}
                        onChange={handleChange}
                        fullWidth
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }}>
                    <TextField
                        label="Envio De:"
                        type="date"
                        name="dataInicial"
                        value={defaultDataDe}
                        onChange={handleChangeDate}
                        fullWidth
                        required={campoObrigatorio}
                        InputLabelProps={{ shrink: true }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }}>
                    <TextField
                        label="Até:"
                        type="date"
                        name="dataFinal"
                        value={defaultDataAte}
                        onChange={handleChangeDate}
                        fullWidth
                        required={campoObrigatorio}
                        InputLabelProps={{ shrink: true }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default FilterFormRelatorioEmailSindico;
