import React from 'react';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Loading from '../../../components/common/Loading';
import Modal from '../../../components/common/Modal';
import '../../../components/common/Relatorio.css';
import { IEmailSindico } from './Interfaces/IEmailSindico';
interface TableDataProps {
  ficha: IEmailSindico[];
  loading: boolean;
  msgAlert: string;
  modalOpen: boolean;
  handleCloseModal: () => void;
}
const TableData: React.FC<TableDataProps> = ({ ficha, loading, msgAlert, modalOpen, handleCloseModal }) => {
  
  const fixDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  };
  const handleDateTime = (date: string) => date ? (date.toString().substring(0, 10) === '0001-01-01' ? "" : fixDateTime(date.toString())) : "";
  
  function formatarNome(nome: string): string {
    return nome
        .split(' ')
        .map(palavra => {
            return palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase();
        })
        .join(' ');
}


  return (
    <div className="responsive-table-container">
      {loading ? <Loading /> : null}
      <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Data de Envio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ficha.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{formatarNome(item.NomeDestinatario)}</TableCell>
                <TableCell>{item.EmailDestinatario}</TableCell>
                <TableCell>{item.StatusAbertura ? 'Visualizado' : 'Não Visualizado'}</TableCell>
                <TableCell>{handleDateTime(item.CreatedAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default TableData;