import React, { useEffect, useState } from 'react';
import { AlertProps, Divider, SelectChangeEvent, Snackbar, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import FilterForm from './FilterForm';
import RelatorioCDLTable from '../../pages/juridico/RelatorioCDL/RelatorioCDLTable';
import RelatorioIntegradorTable from '../../pages/site/RelatorioIntegrador/RelatorioIntegradorTable';
import RelatorioLeadsTable from '../../pages/site/RelatorioLeads/RelatorioLeadsTable';
import ImportarNumeroSorteTable from '../../pages/Seguros/NumerosDaSorte/ImportarNumeroSorteTable';
import Pagination from './Pagination';
import RelatorioEmailAniversarioTable from '../../pages/marketing/RelatorioAniversario/RelatorioEmailAniversarioTable';
import RelatorioDesocupacaoTable from '../../pages/alugueis/desocupacao/relatorio/RelatorioDesocupacaoTable';
import RelatorioDesocupadosTable from '../../pages/alugueis/desocupacao/RelatorioDesocupados/RelatorioDesocupadosTable';
import BloqueioEmailTable from '../../pages/marketing/BloqueioEmail/BloqueioEmailTable';
import RelatorioEmailsOnboardingTable from '../../pages/marketing/RelatorioOnboarding/RelatorioEmailsOnboardingTable';
import RelatorioEsteiraRenovacaoTable from '../../pages/alugueis/RelatorioRenovação/RelatorioEsteiraRenovacaoTable';
import RelatorioErrosRenovacaoTab from '../../pages/alugueis/RelatorioErrosRenovacao/RelatoriosErrosRenovacaoTable';
import RelatorioEmailSindicoTab from '../../pages/juridico/RelatorioEmailSindico/RelatorioEmailSindicoTable';

interface RelatorioProps {
    busca: any;
    exporta: any;
    ficha: any;
    setFicha(ficha: any): void;
    relatorio: string;
}

function downloadBlob(blob: Blob, fileName: string) {
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
}

const Relatorio: React.FC<RelatorioProps> = ({
    busca,
    exporta,
    ficha,
    setFicha,
    relatorio
}) => {
    const [severity, setSeverity] = useState('success');
    const [msgAlert, setMsgAlert] = useState('');
    const [loading, setLoading] = useState(false);

    const [totalPaginas, setTotalPaginas] = useState(0);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensPagina, setItensPagina] = useState(10);

    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const [formData, setFormData] = useState({
        codigo_imovel: ''
    });

    useEffect(() => {
        buscar(formData, paginaAtual, itensPagina);
    }, [paginaAtual, itensPagina]);

    const buscar = async (filtros: any, paginaAtual: number, itensPagina: number, msg = false) => {
        try {
            setLoading(true);
            console.log('filtros ', filtros);

            if (filtros['negocio'] === undefined) {
                filtros['negocio'] = 0;
            }
            if (filtros['estado'] === undefined) {
                filtros['estado'] = 0;
            }
            if (filtros['codigo_imovel'] === undefined || filtros['codigo_imovel'] === "") {
                filtros['codigo_imovel'] = 0;
            }
            if (filtros['dataInicial'] > filtros['dataFinal']) {
                setSeverity("error");
                setMsgAlert("Período selecionado de forma incorreta")
                setOpen(true);
                return;
            }

            console.log('aqui ', busca);
            let retorno = await busca(filtros, paginaAtual, itensPagina);
            console.log('retorno ', retorno, retorno['TotalItens']);

            setFicha(retorno['Itens'] || retorno['itens']);
            setTotalPaginas(retorno['TotalPaginas'] || retorno['totalPaginas']);
            setTotalRegistros(retorno['TotalItens'] || retorno['totalItens']);

            if (msg) {
                setSeverity("success");
                setMsgAlert((retorno['TotalItens'].toString() || retorno['totalItens'].toString()) + " registros encontrados.")
                setOpen(true);
            }
        } catch (err: any) {
            console.log(err.response.data);
            setSeverity("error");
            setMsgAlert(err.response.data['mensagem']);
        } finally {
            setLoading(false);
        }
    };

    const handleGenerateExcel = async () => {
        try {
            setLoading(true);
            if (formData['codigo_imovel'] === undefined || formData['codigo_imovel'] === "") {
                formData['codigo_imovel'] = "0";
            }
            const response = await exporta(formData, 1, itensPagina);
            console.log('response ', response);
            
            const blob = new Blob([response]);

            downloadBlob(blob, "Relatorio_" + relatorio + ".xlsx");
        } catch (error: any) {
            console.log(error.response.data);
            setSeverity("error");
            setMsgAlert("0 registros encontrados.");
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handlePrimeiraPagina = () => {
        setPaginaAtual(1);
    }

    const handleUltimaPagina = () => {
        setPaginaAtual(totalPaginas);
    }

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setPaginaAtual(1);
        buscar(formData, 1, itensPagina, true);
    };

    return (
        <div>
            <Divider sx={{ my: 2 }} />
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
                    {msgAlert}
                </Alert>
            </Snackbar>
            <FilterForm
                handleSubmit={handleSubmit}
                handleChangeItensPagina={(event: SelectChangeEvent<number>) => setItensPagina(Number(event.target.value))}
                handleGenerateExcel={handleGenerateExcel}
                itensPagina={itensPagina}
                formData={formData}
                setFormData={setFormData}
                relatorio={relatorio}
            />
            <div>
                <Divider sx={{ my: 2 }} />
                <div>
                    {relatorio == "CDL" ? (
                        <RelatorioCDLTable ficha={ficha} loading={loading} msgAlert={msgAlert} modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
                    ) : relatorio == "Integrador" ? (
                        <RelatorioIntegradorTable ficha={ficha} loading={loading} msgAlert={msgAlert} modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
                    ) : relatorio == "Leads" ? (
                        <RelatorioLeadsTable ficha={ficha} loading={loading} msgAlert={msgAlert} modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
                    ) : relatorio == "EmailAniversario" ? (
                        <RelatorioEmailAniversarioTable ficha={ficha} loading={loading} msgAlert={msgAlert} modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
                    ) : relatorio == "NumerosDaSorte" ? (
                        <ImportarNumeroSorteTable ficha={ficha} loading={loading} msgAlert={msgAlert} modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
                    ) : relatorio == "Desocupacao" ? (
                        <RelatorioDesocupacaoTable ficha={ficha} loading={loading} msgAlert={msgAlert} modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
                    ) : relatorio == "Desocupados" ? (
                        <RelatorioDesocupadosTable ficha={ficha} loading={loading} msgAlert={msgAlert} modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
                    ) : relatorio == "BloqueioEmail" ? (
                        <BloqueioEmailTable ficha={ficha} setFicha={setFicha} loading={loading} msgAlert={msgAlert} modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
                    ) : relatorio == "EmailOnboarding" ? (
                        <RelatorioEmailsOnboardingTable ficha={ficha} loading={loading} msgAlert={msgAlert} modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
                    ) : relatorio == "EmailEsteiraRenovacao" ? (
                        <RelatorioEsteiraRenovacaoTable ficha={ficha} loading={loading} msgAlert={msgAlert} modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
                    ):  relatorio == "EmailErrosRenovacao" ? (
                        <RelatorioErrosRenovacaoTab ficha={ficha} loading={loading} msgAlert={msgAlert} modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
                    ):  relatorio == "EmailSindico" ? (
                        <RelatorioEmailSindicoTab ficha={ficha} loading={loading} msgAlert={msgAlert} modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
                    ): null}
                </div>
                <Pagination
                    paginaAtual={paginaAtual}
                    totalPaginas={totalPaginas}
                    PaginaAnterior={() => setPaginaAtual(paginaAtual - 1)}
                    ProximaPagina={() => setPaginaAtual(paginaAtual + 1)}
                    IrParaPagina={(pageNumber) => setPaginaAtual(pageNumber)}
                    IrParaPrimeiraPagina={handlePrimeiraPagina}
                    IrParaUltimaPagina={handleUltimaPagina}
                />
                <Typography>Total de Itens: {totalRegistros}</Typography>
            </div>
        </div>
    );
};

export default Relatorio;
